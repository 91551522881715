import { defineStore } from "pinia";
import { Centrifuge } from "centrifuge";

import { api } from "src/boot/axios.js";

import db from "src/db.js";

export const useWebsocketStore = defineStore("websockets", {
  /* need to work out staging url here */
  state: () => ({
    wsURL: process.env.DEV ? "ws://localhost:9005/ws" : "wss://api.4way.io/ws",
    centrifuge: null,
    updates_channel: null,
    global_channels: [],
    handlers: [],
    update_handlers: [],
    create_handlers: [],
    destroy_handlers: [],
  }),

  actions: {
    open() {
      // if (this.centrifuge) return;

      const state = this;
      this.centrifuge = new Centrifuge(this.wsURL, {
        getToken: function (ctx) {
          return state.getToken("ws_issue_jwt", ctx);
        },
      });
      this.centrifuge.on("connected", function (ctx) {
        const updates_channel = state.centrifuge.newSubscription(
          state.updates_channel
        );
        updates_channel.on("publication", state.handle_update);

        state.global_channels.forEach((channel) => {
          const sub = state.centrifuge.newSubscription(channel.channel_name, {
            token: channel.token,
          });
          sub.on("publication", state.handle_global);
          // sub.subscribe();
        });
      });
      this.centrifuge.connect();
    },
    close() {
      if (this.centrifuge) {
        this.centrifuge.disconnect();
      }
    },
    getToken(url, ctx) {
      const state = this;
      return new Promise((resolve, reject) => {
        api(url)
          .then((response) => {
            state.updates_channel = response.data.updates_channel;
            state.global_channels = response.data.global_channels;
            resolve(response.data.token);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addUpdateHandler(name, func) {
      if (this.update_handlers.find((h) => h.name == name)) return;

      this.update_handlers.push({
        name: name,
        func: func,
      });
    },
    addCreateHandler(name, func) {
      if (this.create_handlers.find((h) => h.name == name)) return;

      this.create_handlers.push({
        name: name,
        func: func,
      });
    },
    addDestroyHandler(name, func) {
      if (this.destroy_handlers.find((h) => h.name == name)) return;

      this.destroy_handlers.push({
        name: name,
        func: func,
      });
    },
    removeUpdateHandler(name) {
      this.update_handlers = this.update_handlers.filter((h) => h.name != name);
    },
    removeCreateHandler(name) {
      this.create_handlers = this.create_handlers.filter((h) => h.name != name);
    },
    removeDestroyHandler(name) {
      this.destroy_handlers = this.destroy_handlers.filter(
        (h) => h.name != name
      );
    },
    addHandler(channel_name, func) {
      this.handlers[channel_name] = func;
    },
    removeHandler(channel_name) {
      this.handlers = this.handlers.filter(
        (handler) => handler.channel_name != channel_name
      );
    },
    handle_update(ctx) {
      const models = {
        contact: "contacts",
        contactemail: "contact_emails",
        contactphone: "contact_phones",
        conversation: "conversations",
        file: "files",
        journaobject: "jobjects",
        location: "locations",
        message: "messages",
        node: "nodes",
        invoice: "invoices",
        transaction: "transactions",
        routable: "routables",
        tag: "tags",
        timeclock: "timeclocks",
      };

      let message = ctx.data;

      if (["update", "create"].includes(message.action)) {
        db[models[message.model]].orm().save(message.instance);
        this[`${message.action}_handlers`].forEach((handler) => {
          handler.func(models[message.model], message.instance);
        });
      }
      if (message.action == "destroy") {
        this.destroy_handlers.forEach((handler) => {
          handler.func(models[message.model], message.instance_id);
        });
        db[models[message.model]].orm().destroy(message.instance_id);
      }
    },
    handle_global(ctx) {
      if (this.handlers.hasOwnProperty(ctx.channel)) {
        this.handlers[ctx.channel](ctx.data);
      }
    },
  },
});
