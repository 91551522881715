import { Model, useRepo } from "pinia-orm";

import { BaseModel } from "src/models/Base.js";

import { Contact, LocationContact } from "src/models/Contact.js";
import { Node } from "src/models/Node.js";
import { User } from "src/models/User.js";
import { File } from "src/models/File.js";
import { JournaObject } from "src/models/Jobject.js";
import { Message } from "src/models/Message.js";
import { Tag } from "src/models/Tag.js";
import { Timeclock } from "src/models/Timeclock.js";

export class Location extends BaseModel {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      alias: this.string(""),
      street_1: this.string(""),
      street_2: this.string(""),
      city: this.string(""),
      state: this.string(""),
      postal_code: this.string(""),
      country: this.string("United States"),
      location: this.attr({}),
      enable: this.boolean(true),
      enable_marketing: this.boolean(true),
      is_verified: this.boolean(),
      userId: this.attr(null),
      data: this.attr({}),
      //nodes: this.belongsToMany(Node, NodeLocation, "locationId", "nodeId"),
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, "file_ids"),
      jobject_ids: this.attr([]),
      jobjects: this.hasManyBy(JournaObject, "jobject_ids"),
      message_ids: this.attr([]),
      messages: this.hasManyBy(Message, "message_ids"),
      node_ids: this.attr([]),
      nodes: this.hasManyBy(Node, "node_ids"),
      tag_ids: this.attr([]),
      tags: this.hasManyBy(Tag, "timeclock_ids"),
      timeclock_ids: this.attr([]),
      timeclocks: this.hasManyBy(Timeclock, "timeclock_ids"),
    };
  }
  /*
  static saving(model) {
    useRepo(LocationContact).save(
      model.contact_ids.map((id) => {
        return { locationId: model.id, contactId: id };
      })
    );
  }
  static deleting(model) {
    useRepo(LocationContact)
      .where((pivot) => model.contact_ids.includes(pivot.contactId))
      .where("locationId", model.id)
      .delete();
  }
  */
  get pivotMap() {
    return {
      contacts: useRepo(LocationContact),
    };
  }
}
Location.entity = "locations";

/*class NodeLocation extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      locationId: this.uid(),
    };
  }
}
NodeLocation.entity = "node_location";
NodeLocation.primaryKey = ["nodeId", "locationId"];*/
/*
class ContactLocation extends Model {
  static fields() {
    return {
      groupId: this.uid(),
      userId: this.uid(),
    };
  }
}
ContactLocation.entity = "contact_location";
ContactLocation.primaryKey = ["contactId", "locationId"];
*/
