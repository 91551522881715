import { Model } from "pinia-orm";

export class User extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      date_joined: this.attr(null),
      last_login: this.attr(null),
      username: this.string(""),
      first_name: this.string(""),
      last_name: this.string(""),
      email: this.string(""),
      is_active: this.boolean(false),
      data: this.attr({}),
      groups: this.belongsToMany(Group, GroupUser, "userId", "groupId"),
    };
  }
}
User.entity = "users";

class GroupUser extends Model {
  static fields() {
    return {
      groupId: this.uid(),
      userId: this.uid(),
    };
  }
}
GroupUser.entity = "group_user";
GroupUser.primaryKey = ["groupId", "userId"];

export class Group extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      name: this.string(""),
    };
  }
}
Group.entity = "groups";
