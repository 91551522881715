import { Model } from "pinia-orm";

import { User } from "src/models/User.js";
import { Node } from "src/models/Node.js";

export class Tag extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      name: this.string(""),
      node_ids: this.attr([]),
      nodes: this.hasManyBy(Node, "node_ids"),
    };
  }
}
Tag.entity = "tags";

class NodeTag extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      tagId: this.uid(),
    };
  }
}
NodeTag.entity = "node_tag";
NodeTag.primaryKey = ["nodeId", "tagId"];
