import { Model } from "pinia-orm";

export class BaseModel extends Model {
  static saving(model, data) {
    const fields = model.$fields();
    for (const name in fields) {
      if (
        fields[name].constructor.name == "HasManyBy" &&
        "pivotMap" in fields[name].model &&
        fields[name].model.pivotMap.hasOwnProperty(name)
      ) {
        fields[name].model.pivotMap[name].save(
          model[fields[name].foreignKey].map((id) => {
            return {
              [fields[name].model.pivotMap[name].model.constructor
                .primaryKey[0]]: model.id,
              [fields[name].model.pivotMap[name].model.constructor
                .primaryKey[1]]: id,
            };
          })
        );
      }
    }
  }
  static deleting(model) {
    const fields = model.$fields();
    for (const name in fields) {
      if (
        fields[name].constructor.name == "HasManyBy" &&
        "pivotMap" in fields[name].model &&
        fields[name].model.pivotMap.hasOwnProperty(name)
      ) {
        fields[name].model.pivotMap[name]
          .where(
            fields[name].model.pivotMap[name].model.constructor.primaryKey[0],
            model.id
          )
          .where((pivot) =>
            model[fields[name].foreignKey].includes(
              pivot[
                fields[name].model.pivotMap[name].model.constructor
                  .primaryKey[1]
              ]
            )
          )
          .delete();
      }
    }
  }
  /*
  static updating(model) {
    console.log(newData);
  }
  */
  /*
  static updating(model) {
    const fields = model.$fields();
    for (const name in fields) {
      if (fields[name].constructor.name == "BelongsTo") {
        console.log(model);
        console.log(model[name]);
        console.log(`found null BelongsTo ${name} ${fields[name].foreignKey}`);
        // model[fields[name].foreignKey] = null;
      }
    }
  }
  */
}
