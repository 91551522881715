import { Model } from "pinia-orm";

import { BaseModel } from "src/models/Base.js";

import { Tag } from "src/models/Tag.js";
import { User } from "src/models/User.js";
import { Message } from "src/models/Message.js";
import { Node } from "src/models/Node.js";
import { File } from "src/models/File.js";

export class Timeclock extends BaseModel {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      break_time: this.string(""),
      hourly_pay_rate: this.string(""),
      hourly_billable_rate: this.string(""),
      clocked_in_at: this.attr(null),
      clocked_out_at: this.attr(null),
      user_confirmed_at: this.attr(null),
      sup_confirmed_at: this.attr(null),
      paid_at: this.attr(null),
      clock_in_location: this.attr(null),
      clock_out_location: this.attr(null),
      tag: this.string(""),
      tags: this.belongsToMany(Tag, TagTimeclock, "timeclockId", "tagId"),
      clockedInById: this.attr(null),
      clockedOutById: this.attr(null),
      userConfirmedById: this.attr(null),
      supConfirmedById: this.attr(null),
      paidById: this.attr(null),
      clocked_in_by: this.belongsTo(User, "clockedInById"),
      clocked_out_by: this.belongsTo(User, "clockedOutById"),
      user_confirmed_by: this.belongsTo(User, "userConfirmedById"),
      sup_confirmed_by: this.belongsTo(User, "supConfirmedById"),
      paid_by: this.belongsTo(User, "paidById"),
      userId: this.attr(null),
      user: this.belongsTo(User, "userId"),
      leaderId: this.attr(null),
      leader: this.belongsTo(User, "leaderId"),
      node_id: this.attr(null),
      node: this.belongsTo(Node, "node_id"),
      messages: this.hasMany(Message, "timeclock_id"),
      files: this.hasMany(File, "timeclock_id"),
    };
  }
}
Timeclock.entity = "timeclocks";

class TagTimeclock extends Model {
  static fields() {
    return {
      tagId: this.attr(null),
      timeclockId: this.attr(null),
    };
  }
}
TagTimeclock.entity = "tag_timeclock";
TagTimeclock.primaryKey = ["tagId", "timeclockId"];
