import { Model } from "pinia-orm";

import { User } from "src/models/User.js";

export class Customer extends Model {
  static fields() {
    return {
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      payment_status: this.string(""),
      under_contract: this.boolean(false),
      data: this.attr({}),
    };
  }
}
Customer.entity = "customers";
