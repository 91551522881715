import { Model } from "pinia-orm";
import { User } from "src/models/User.js";
import { File } from "src/models/File.js";
import { Timeclock } from "src/models/Timeclock.js";

export class Conversation extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      participants_hash: this.attr(null),
      method_key: this.string(null),
      permissions: this.attr([]),
      node: this.attr(null),
      files: this.attr([]),
      messages: this.attr([]),
    };
  }
}
Conversation.entity = "conversations";

export class Message extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      is_draft: this.boolean(true),
      sender: this.attr(null),
      recipients: this.attr(null),
      recipients_cc: this.attr(null),
      recipients_bcc: this.attr(null),
      metadata: this.attr(null),
      subject: this.string(""),
      body: this.string(""),
      timeclock_id: this.attr(null),
      timeclock: this.belongsTo(Timeclock, "timeclock_id"),
      node: this.attr(null),
      node_path_text: this.attr(null),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, "file_ids"),
      conversation: this.attr(null),
      //conversation: this.belongsTo(Conversation, "conversation_id"),
    };
  }
}
Message.entity = "messages";

class MessageFile extends Model {
  static fields() {
    return {
      messageId: this.uid(),
      fileId: this.uid(),
    };
  }
}
MessageFile.entity = "message_file";
MessageFile.primaryKey = ["messageId", "fileId"];

/*class MessageConversation extends Model {
  static fields() {
    return {
      messageId: this.uid(),
      conversationId: this.uid(),
    };
  }
}
MessageConversation.entity = "message_conversation";
MessageConversation.primaryKey = ["messageId", "conversationId"];*/
