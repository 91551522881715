import { Model, useRepo } from "pinia-orm";

import { Contact } from "src/models/Contact.js";
import { File } from "src/models/File.js";
// import { Invoice, PaymentSeries, Transaction } from "src/models/Payment.js";
import { Message } from "src/models/Message.js";
import { Node } from "src/models/Node.js";
import { User } from "src/models/User.js";

export class JobjectNode extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      jobjectId: this.uid(),
    };
  }
}
JobjectNode.entity = "node_jobject";
JobjectNode.primaryKey = ["nodeId", "jobjectId"];

class JobjectContact extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      contactId: this.uid(),
    };
  }
}
JobjectContact.entity = "jobject_contact";
JobjectContact.primaryKey = ["jobjectId", "contactId"];

class JobjectInvoice extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      invoiceId: this.uid(),
    };
  }
}
JobjectInvoice.entity = "jobject_invoice";
JobjectInvoice.primaryKey = ["jobjectId", "invoiceId"];

class JobjectFile extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      fileId: this.uid(),
    };
  }
}
JobjectFile.entity = "jobject_file";
JobjectFile.primaryKey = ["jobjectId", "fileId"];

class JobjectLink extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      linkId: this.uid(),
    };
  }
}
JobjectLink.entity = "jobject_link";
JobjectLink.primaryKey = ["jobjectId", "linkId"];

class JobjectMessage extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      messageId: this.uid(),
    };
  }
}
JobjectMessage.entity = "jobject_message";
JobjectMessage.primaryKey = ["jobjectId", "messageId"];

/*
class JobjectPaymentSeries extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      paymentSeriesId: this.uid(),
    };
  }
}
JobjectPaymentSeries.entity = "jobject_payment_series";
JobjectPaymentSeries.primaryKey = ["jobjectId", "paymentSeriesId"];
*/

class JobjectTransaction extends Model {
  static fields() {
    return {
      jobjectId: this.uid(),
      transactionId: this.uid(),
    };
  }
}
JobjectTransaction.entity = "jobject_transaction";
JobjectTransaction.primaryKey = ["jobjectId", "transactionId"];

const fieldsMap = [
  {
    field: "contact_ids",
    repo: useRepo(JobjectContact),
    relationKey: "contactId",
  },
  {
    field: "file_ids",
    repo: useRepo(JobjectFile),
    relationKey: "fileId",
  },
  /*
  {
    field: "invoice_ids",
    repo: useRepo(JobjectInvoice),
    relationKey: "invoiceId",
  },
  */
  {
    field: "link_ids",
    repo: useRepo(JobjectLink),
    relationKey: "linkId",
  },
  {
    field: "message_ids",
    repo: useRepo(JobjectMessage),
    relationKey: "messageId",
  },
  {
    field: "node_ids",
    repo: useRepo(JobjectNode),
    relationKey: "nodeId",
  },
  /*
  {
    field: "payment_series_ids",
    repo: useRepo(JobjectPaymentSeries),
    relationKey: "paymentSeriesId",
  },
  {
    field: "transaction_ids",
    repo: useRepo(JobjectTransaction),
    relationKey: "transactionId",
  },
  */
];

export class JournaObject extends Model {
  static fields() {
    return {
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      variety: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      is_draft: this.boolean(true),
      name: this.string(""),
      description: this.string(""),
      data: this.attr({}),
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, "file_ids"),
      invoice_ids: this.attr([]),
      // invoices: this.hasManyBy(Invoice, "invoice_ids"),
      link_ids: this.attr([]),
      links: this.hasManyBy(JournaObject, "link_ids"),
      message_ids: this.attr([]),
      messages: this.hasManyBy(Message, "message_ids"),
      node_ids: this.attr([]),
      nodes: this.hasManyBy(Node, "node_ids"),
      payment_series_ids: this.attr([]),
      // payment_series: this.hasManyBy(PaymentSeries, "payment_series_ids"),
      transaction_ids: this.attr([]),
      // transactions: this.hasManyBy(Transaction, "transaction_ids"),
    };
  }
  static saving(model) {
    fieldsMap.forEach((f) => {
      f.repo.save(
        model[f.field].map((id) => {
          return { nodeId: model.id, [f.relationKey]: id };
        })
      );
    });
  }
  static deleting(model) {
    fieldsMap.forEach((f) => {
      f.repo
        .where((pivot) => model[f.field].includes(pivot[f.relationKey]))
        .where("nodeId", model.id)
        .delete();
    });
  }
}
JournaObject.entity = "jobjects";
