import { Model, useRepo } from "pinia-orm";

import { Contact } from "src/models/Contact.js";
import { Customer } from "src/models/Payment.js";
import { File } from "src/models/File.js";
import { JournaObject } from "src/models/Jobject.js";
import { Location } from "src/models/Location.js";
import { Message } from "src/models/Message.js";
import { User } from "src/models/User.js";

class RoutableContact extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      contactId: this.uid(),
    };
  }
}
RoutableContact.entity = "routable_contact";
RoutableContact.primaryKey = ["routableId", "contactId"];

class RoutableFile extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      fileId: this.uid(),
    };
  }
}
RoutableFile.entity = "routable_file";
RoutableFile.primaryKey = ["routableId", "fileId"];

export class RoutableJobject extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      jobjectId: this.uid(),
    };
  }
}
RoutableJobject.entity = "routable_jobject";
RoutableJobject.primaryKey = ["routableId", "jobjectId"];

class RoutableMessage extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      messageId: this.uid(),
    };
  }
}
RoutableMessage.entity = "routable_message";
RoutableMessage.primaryKey = ["routableId", "messageId"];

class RoutableLocation extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      locationId: this.uid(),
    };
  }
}
RoutableLocation.entity = "routable_location";
RoutableLocation.primaryKey = ["routableId", "locationId"];

class RoutableCustomer extends Model {
  static fields() {
    return {
      routableId: this.uid(),
      customerId: this.uid(),
    };
  }
}
RoutableCustomer.entity = "routable_customer";
RoutableCustomer.primaryKey = ["routableId", "customerId"];

const fieldsMap = [
  {
    field: "contact_ids",
    repo: useRepo(RoutableContact),
    relationKey: "contactId",
  },
  {
    field: "customer_ids",
    repo: useRepo(RoutableCustomer),
    relationKey: "customerId",
  },
  {
    field: "file_ids",
    repo: useRepo(RoutableFile),
    relationKey: "fileId",
  },
  {
    field: "jobject_ids",
    repo: useRepo(RoutableJobject),
    relationKey: "jobjectId",
  },
  {
    field: "location_ids",
    repo: useRepo(RoutableLocation),
    relationKey: "locationId",
  },
  {
    field: "message_ids",
    repo: useRepo(RoutableMessage),
    relationKey: "messageId",
  },
];

export class Routable extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      completedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      completed_by: this.belongsTo(User, "completedById"),
      parent: this.attr(null),
      kind: this.string(""),
      variety: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      is_draft: this.boolean(true),
      is_auto_completed: this.boolean(false),
      path: this.string(""),
      path_text: this.attr([]),
      name: this.string(""),
      pid: this.string(""),
      status: this.string(""),
      priority: this.string(""),
      due_date: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      send_date: this.attr(null),
      time_estimate: this.number(0),
      frequency: this.number(0),
      is_recurring: this.boolean(false),
      is_routable: this.boolean(false),
      step: this.number(0),
      stop: this.number(0),
      data: this.attr({}),
      is_leaf: this.boolean(true),
      permissions: this.attr([]),

      // relationships
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
      customer_ids: this.attr([]),
      customers: this.hasManyBy(Customer, "customer_ids"),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, "file_ids"),
      jobject_ids: this.attr([]),
      jobjects: this.hasManyBy(JournaObject, "jobject_ids"),
      location_ids: this.attr([]),
      // locations: this.hasManyBy(Location, "location_ids"),
      locations: this.attr([]),
      message_ids: this.attr([]),
      messages: this.hasManyBy(Message, "message_ids"),
      annotations: this.attr(null),
    };
  }
  static saving(model) {
    fieldsMap.forEach((f) => {
      f.repo.save(
        model[f.field].map((id) => {
          return { routableId: model.id, [f.relationKey]: id };
        })
      );
    });
  }
  static deleting(model) {
    fieldsMap.forEach((f) => {
      f.repo
        .where((pivot) => model[f.field].includes(pivot[f.relationKey]))
        .where("routableId", model.id)
        .delete();
    });
  }

  get assigned() {
    return this.permissions.filter(
      (subject) => !subject.is_group && subject.permissions.includes("assigned")
    );
  }
  get viewers() {
    return this.permissions.filter(
      (subject) =>
        !subject.is_group && subject.permissions.includes("view_node")
    );
  }
  get changers() {
    return this.permissions.filter(
      (subject) =>
        !subject.is_group && subject.permissions.includes("change_node")
    );
  }
}
Routable.entity = "routables";
